import React, { Component } from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"

class BlogItemTemplate extends Component {
  render() {
    const post = this.props.data.wordpressPost

    return (
      <Layout>
        <div className="bloglist">

          <div className="row">
            <div className="col-xs-12">
              <h2 className="">{post.wps_subtitle}</h2>
    		      <h1 className="">{post.title}</h1>
      	    </div>
          </div>

          <div className="row">
            <div className="col-xs-12">
      		    <ul className="arrowlist taglist list-inline pl-5">

              {post.tags.map((tag) => (
                <li className="list-inline-item" key={`tag-${tag.wordpress_id}`}>
                  <Link to={`/blog/tag/${tag.slug}/`} rel="tag">{tag.name}</Link>
                </li>
              ))}

              </ul>
            </div>
          </div>

          <div className="row">
            <div className="col-xs-12" dangerouslySetInnerHTML={{ __html: post.content }}>
            </div>
          </div>

        </div>
      </Layout>
    )
  }
}

export default BlogItemTemplate

export const query = graphql`
  query currentBlogQuery($id: String!) {
    wordpressPost(id: { eq: $id }) {
      wordpress_id
      title
      content
      slug
      date(formatString: "MMMM DD, YYYY")
      wps_subtitle
      tags {
        id
        wordpress_id
        name
        slug
      }
    }
  }
`
